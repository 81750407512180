import React from 'react';
import styled from 'styled-components';

import HexLight from './../../../img/services/hex/hex-bg-light-blue.svg';
import HexMed from './../../../img/services/hex/hex-bg-med-blue.svg';
import HexDark from './../../../img/services/hex/hex-bg-dark-blue.svg';

//this is used on copywriting, seo, earned media, and influencer service pages
//check these pages when making changes to this module

const Wrapper = styled.div`
  max-width: calc(100% - 80px);
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 82px;
  &.extra-padding {
    padding-bottom: 90px;
  }
  .inner {
    max-width: 726px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 31px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    .hex-row {
      height: 203px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      .wrap-hex {
        .hex {
          width: 230px;
          height: 265px;
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
      .wrap-content {
        padding-left: 36px;
        h2 {
          font-family: Oswald;
          font-style: normal;
          font-weight: 300;
          font-size: 32px;
          line-height: 36px;
          display: flex;
          align-items: center;
          color: #007dba;
          margin-bottom: 15px;
          margin-top: 0;
        }
        .html {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 26px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #585858;
        }
      }
    }
  }
  .heading {
    h2 {
      font-family: Oswald;
      font-style: normal;
      font-weight: 200;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      text-transform: uppercase;
      color: #585858;
      margin-top: 0;
      margin-bottom: 52px;
    }
    h3 {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: center;
      color: #172e56;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 750px) {
    .inner .hex-row {
      height: auto;
      margin-bottom: 20px;
      display: block;
      .wrap-content {
        z-index: 10;
        border: 1px solid #e6e6e6;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 20px;
        margin-left: 125px;
        margin-top: 73px;
        margin-bottom: 70px;
        h2 {
          margin-bottom: 10px;
          margin-top: 0;
        }
      }
      .wrap-hex {
        width: 110px;
        height: 132.5px;

        .hex {
          margin-top: -65px;
        }
      }
    }
    @media (max-width: 600px) {
      .inner .hex-row .wrap-hex .hex {
        background-size: 120px auto;
      }
      .inner .hex-row {
        .wrap-content {
          margin-top: -25px;
          margin-left: 68px;
        }
      }
    }
    @media (max-width: 530px) {
      .inner .hex-row .wrap-hex {
        width: 30px;
      }
    }
  }
`;

function quote(props) {
  const img = [HexLight, HexMed, HexDark, HexLight, HexMed, HexDark];

  return (
    <Wrapper
      style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : '#FFF' }}
      className={`${props.backgroundColor ? 'extra-padding' : ''}`}
    >
      <div className="inner heading">
        {props.subTitle && <h3>{props.subTitle}</h3>}
        {props.title && <h2>{props.title}</h2>}
      </div>
      <div className="inner">
        {props.data.map((row, index) => (
          <div className="hex-row" key={index}>
            <div className="wrap-hex">
              <div
                className="hex"
                style={{
                  zIndex: index,
                  backgroundImage: `url('${
                    row.bgImage && row.bgImage.publicURL ? row.bgImage.publicURL : img[index] ? img[index] : ''
                  }')`,
                }}
              ></div>
            </div>
            <div className="wrap-content">
              <h2>{row.title}</h2>
              <div className="html" dangerouslySetInnerHTML={{ __html: row.html }}></div>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

export default quote;
