import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 30px;
  background: rgba(188, 224, 244, 0.3);

  .inner {
    max-width: 940px;
    width: 100%;
    margin: 0 auto;
    padding-top: 75px;
    padding-bottom: 75px;

    .copy {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 42px;
      color: #585858;
      b,
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 42px;
        margin: 0;
        padding: 0;
        display: inline;

        font-weight: bold;
        color: #01426a;
      }
      .spacer {
        height: 18px;
      }
      .col-2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        grid-gap: 4%;
        margin-top: 20px;
        flex-wrap: wrap;
        .column {
          max-width: 100%;
          width: 100%;
          b {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 21px;
          }
          a {
            text-decoration: none;
            color: #01426a;
            font-weight: 400;
            &:hover {
              text-decoration: underline;
            }
          }
          ul {
            margin-top: 0;
            padding-left: 56px;
            li {
              font-family: Roboto;
              font-size: 16px;
              line-height: 26px;
              font-weight: 300;
              letter-spacing: 0.02em;
            }
          }
        }
        @media (min-width: 768px) {
          flex-wrap: nowrap;
          .column {
            max-width: 48%;
          }
        }
      }
    }
  }
`;

function SubHeader(props) {
  return (
    <Wrapper>
      <div className="inner">
        <div className="copy" dangerouslySetInnerHTML={{ __html: props.html }}></div>
      </div>
    </Wrapper>
  );
}

export default SubHeader;
