import React from 'react';
import styled from 'styled-components';
import Button from './button';
import BgHexes from './../../../img/services/bg-bubbles.svg';

//this is used in 12 services pages
//NOT in visual design, website dev, cause campaign, events, and influencer services

const Wrap = styled.div`
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  .borderIntroText {
    h2 {
      font-size: 32px;
      font-weight: 300;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      color: #000;
      margin-top: 0;
      margin-bottom: 20px;
    }
    h3 {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: #067eba;
      margin-top: 20px;
      margin-bottom: 67px;
    }
  }
  .inner {
    margin: 0 auto;
    max-width: calc(1262px - 120px);
    border: 2px solid #41b6e6;
    /* background-color: white; */
    color: #585858;
    padding-top: 80px;
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    h1 {
      font-family: Oswald;
      font-style: normal;
      font-weight: 200;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      text-transform: uppercase;
      color: #585858;
      width: 100%;
      margin-bottom: 50px;
      margin-top: 0;
      &.light-text {
        color: white;
      }
    }
    .item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;

      box-sizing: border-box;
      margin: 0px 60px 50px 0;

      .title {
        font-family: Oswald;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 36px;
        color: #007dba;
        margin-bottom: 25px;
        &.light-text {
          color: #bce0f4;
        }
      }
      .body {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 2%;
        color: #585858;
        &.light-text {
          color: white;
        }
      }
      .link-wrapper {
        margin-top: 32px;
      }

      position: relative;
      .bg-hexes {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 0;
      }
    }

    &.column-2 div.item {
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 60px);
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      @media (max-width: 750px) {
        width: 100%;
        margin-right: 0;
        &:last-of-type {
          margin-bottom: 0;
        }
        /* .bg-hexes { display:none; } */
      }
    }
    &.column-2 .hexes {
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 60px);
    }
    &.column-3 div.item {
      width: calc(1 / 3 * 100% - (1 - 1 / 3) * 60px);
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      @media (max-width: 950px) {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 60px);
        &:nth-of-type(3n) {
          margin-right: 40px;
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }

      @media (max-width: 750px) {
        width: 100%;
        margin-right: 0;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &.column-3 .hexes {
      width: calc(1 / 3 * 100% - (1 - 1 / 3) * 60px);
    }
  }

  @media (max-width: 750px) {
    width: calc(100% - 40px);
    padding-right: 20px;
    padding-left: 20px;
    .inner {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 40px;
      padding-bottom: 40px;

      .item {
        .title {
          margin-bottom: 15px;
        }
        margin-bottom: 30px;
      }
      .hexes {
        display: none;
      }
    }
  }
`;

function serviceBorder(props) {
  return (
    <Wrap style={{ backgroundImage: props.bgImage ? `url(${props.bgImage})` : 'none' }}>
      <div className="borderIntroText">
        {props.outerTitle && <h2>{props.outerTitle}</h2>}
        {props.subTitle && <h3>{props.subTitle}</h3>}
      </div>
      <div
        className={props.col ? `inner column-${props.col}` : 'inner column-2'}
        style={{ border: props.bgImage ? 'none' : '2px solid #41B6E6' }}
      >
        {props.extraPaddingTop && <span style={{ height: `${props.extraPaddingTop}px`, width: '100%' }}></span>}
        {props.title && <h1 className={props.lightText && 'light-text'}>{props.title}</h1>}

        {props.data.map((row, index) => (
          <div className="item" key={index}>
            <div>
              <div className={props.lightText ? 'title light-text' : 'title'}>{row.title}</div>
              <div
                className={props.lightText ? 'body light-text' : 'body'}
                dangerouslySetInnerHTML={{ __html: row.html }}
              ></div>
            </div>
            {row.linkPath ? (
              <div className="link-wrapper">
                <Button text={row.linkText ? row.linkText : 'Tell Me More'} path={row.linkPath} />
              </div>
            ) : (
              ''
            )}
          </div>
        ))}

        {props.data.length % props.col != 0 ? (
          <span className="hexes">
            <img className="bg-hexes" src={BgHexes} />
          </span>
        ) : (
          ''
        )}
      </div>
    </Wrap>
  );
}
export default serviceBorder;
