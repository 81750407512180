

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .pad-full-width {
    width: calc(100% - 80px);
    padding-right: 40px;
    padding-left: 40px;
  }
  h5 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 72px;
    text-align: center;
    color: #01426A;
    margin: 0;
    line-height: 65px;
    text-transform: uppercase;

    @media (max-width: 980px) {
      font-size: 50px;
      line-height: 45px;
    }
    @media (max-width: 710px) {
      line-height: 50px;
      margin-bottom: 15px;
    }
  }

  .bg {
    background-color: #01426A;
    width: 100%;    
    height: 293px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  .footer-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #BCE0F4;
    position: absolute; 
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 45px;
  }

  .wrap-item {
    padding-left: 15px;
    padding-right: 15px;

    /* font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: 0.02em; */
    color: #FFFFFF;
    white-space: nowrap;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    
    &:nth-child(even) {
      /* -webkit-text-stroke: 2px white; */
      stroke: 2px white;
      /* text-stroke: 2px #000; */
      color:white;
    }
  }


  .wrap-marquee {
    position: absolute;
    /* box-shadow: 0 5px 15px rgba(0,0,0,.5); */
    /* background-color: #001827; */
    width: 120%;
    margin-left: -10%;
    height: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
  }

  .marquee .text {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 50s linear infinite;
    /* animation-duration */
    animation-delay: -25s;
  }

  .marquee2 .text {
    animation-delay: 0s;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
  .bg {
    .wrap-marquee:nth-child(1) {
      top: 50px;
      /* transform: rotate(-15deg) translateY(10px) translateX(0px); */
      z-index: 1;
    }
    .wrap-marquee:nth-child(2) {
      top: 100px;
      /* transform: rotate(8deg) translateY(-20px) translateX(0px); */
      z-index: 2;
    }
    .wrap-marquee:nth-child(3) {
      top: 145px;
      /* transform: rotate(18deg) translateY(10px) translateX(0px); */
      z-index: 2;
    }
  }
`;

function ScrollingText(props) {
  let textLines = props.textLines;
  textLines.forEach( (row, index) => {
    textLines[index].push.apply(textLines[index], row);
  });
  return (
    <Wrapper>
      <h5 className="pad-full-width">{props.title}</h5>

      <div className="bg">

          {textLines.map( (row, index) => (
            <span className="wrap-marquee" key={index}>
              <span className="marquee">
                <span className="text">
                  {row.map( (text, index) => (
                    <span className="wrap-item" key={index}>{text}</span>
                  ))}
                </span>
              </span>
              <span className="marquee marquee2">
                <span className="text">
                  {row.map( (text, index) => (
                    <span className="wrap-item" key={index}>{text}</span>
                  ))}
                </span>
              </span>
            </span>
          ))}

          <h3 className="footer-title">{props.footerTitle}</h3>
      </div>
      
    </Wrapper>
  );
}

export default ScrollingText;
