import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  /* width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px; */
  width: 100%;

  h1 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    text-transform: uppercase;
    color: #585858;
    margin-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .inner {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }

  .wrap-item {
    /* width: 175px; */
    /* padding-left: 37.5px;
    padding-right: 37.5px; */
    /* margin-right: 28px; */
    img {
      margin-right: 51.5px;
      margin-left: 51.5px;
      margin-bottom: 19px;
    }
    .label {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #585858;
    }
  }

  .wrap-marquee {
    position: relative;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
  }

  .marquee .text {
    width: 100%;
    /* display: inline-block; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;


    /* padding-left: 0%; */
    margin-left: 0%;
    animation: marquee 50s linear infinite;
    /* animation-duration */
    animation-delay: -25s;
    /* border: 1px solid blue; */
  }
  .marquee2 .text {
    animation-delay: 0s;
    margin-left: 50%;
    /* border: 1px solid green; */
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  .fade {
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    box-shadow: 0 0 11px 12px white;
    z-index: 10;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }

  @media (max-width: 650px) {
    .wrap-item {
      /* width: 175px;
      padding-left: 37.5px;
      padding-right: 37.5px;
      margin-right: 28px; */
      img {
        margin-right: 30.5px;
        margin-left: 30.5px;
        margin-bottom: 19px;
        width: 75px
      }
    }
  }
`;

function ScrollingIcons(props) {

  let dupCount = props.dupCount ? props.dupCount : 1;

  let data = props.data;
  for(let x = 1; x <= dupCount; x++) {
    data = data.concat(...props.data);
  }
  

  return (
    <Wrapper>
      <h1>{props.title}</h1>
      <div className="inner" style={{height: props.height ? `${props.height}px` : '200px' }}>
        <div className="fade left"></div>
        <div className="fade right"></div>

        <div className="wrap-marquee">
          <span className="marquee" key="marquee1">
            <span className="text">
            {data.map( (row, index) => (
              <span className="wrap-item" key={`markee1-${index}`}>
                <img src={row.icon.publicURL} width={props.itemWidth ? `${props.itemWidth}px` : '175px'} />
                { row.text && <div className="label">{row.text}</div> }
              </span>
            ))}
            </span>
          </span>
          <span className="marquee marquee2" key="marquee2">
            <span className="text">
            {data.map( (row, index) => (
              <span className="wrap-item" key={`markee2-${index}`}>
                <img src={row.icon.publicURL} width={props.itemWidth ? `${props.itemWidth}px` : '175px'} />
                { row.text && <div className="label">{row.text}</div> }
              </span>
            ))}
            </span>
          </span>
        </div>
      </div>
    </Wrapper>
  );
}

export default ScrollingIcons;
